export function DaysDiff(a: Date, b: Date): number {
    // Calculate the difference in milliseconds between the two dates
    const difference = Math.abs(a.getTime() - b.getTime());

    // Number of milliseconds in a day
    const millisecondsInDay = 24 * 60 * 60 * 1000;

    return difference / millisecondsInDay;
}

export function formatDate(date) {
    date = new Date(date)

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const month = monthNames[date.getMonth()];
    const day = date.getDate();

    return `${month} ${day}`;
}

// Function to get an array of dates between two dates in "YYYY-MM-DD" format
export function getDatesRange(startDate: Date, endDate: Date) {
    const dates: string[] = [];
    let currentDate = new Date(startDate);
    const addDays = function (days: number) {
        // eslint-disable-next-line
        // @ts-ignore
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };
    while (currentDate <= endDate) {
        const formattedDate = currentDate.toISOString().split('T')[0];
        dates.push(formattedDate);
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
}