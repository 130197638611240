export default class BoardAction {
    square: number;
    name?: string;
    result?: number;
    reward?: number;
    nudge?: {
        x: number,
        y: number
    };
    phrases?: string[]
}