export default class ChimpAdventureStats {
    date: Date;
    wallet: string;
    dojoId: number;
    end: number;
    endPlace: number;
    squareEndPlace: number;
    endTied: number;
    episodeGuaranteed: boolean;
    xp: number;
    chimpers: number;

    // Adventure 4
    greenBandana: number;
    merchPack: number;
    eboy: number;
    oceanFlame: number;
    canvas: number;
    hogosha: number;
    kaori: number;
    okina: number;
    tengu: number;
    rockJewel: number;
    windJewel: number;
    wwwRaffle: number;
    gemChange: number;
    gemAura: number;
    gemColor: number;
    wagmiRaffle: number;
    tshirt: number;

    // Adventure 5
    lineart: number;
    justinPixelworks: number;
    momochi: number;
    kyoju: number;
    uragirimono: number;
    michi: number;
    fireJewel: number;
    aquaJewel: number;
    chocolate: number;

    // Adventure 6
    chimpChains: number;
    odiousPixelworks: number;
    kermitify: number;
    oda: number;
    kami: number;
    asayaka: number;
    suki: number;
    natureJewel: number;
    instagramMerchPack: number;
    blueBandana: number;

    // --------------------------
    // Obsoletes:
    // ---------------------------
    // bugattiSuitcase: number;
    // greenBandana: number;
    // rektChimp: number;
    // oceanFlame: number;
    // washi: number;
    // majishan: number;
    // fuhai: number;
    // ryuSenshi: number;
    // canvas: number;
    // windJewel: number;
    // aquaJewel: number;
    // gemAura: number;
    // gemChange: number;
    // gemColor: number;
}
