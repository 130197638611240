import { Component } from "vue-property-decorator";

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
]);

// Visit for more information:
// https://class-component.vuejs.org/guide/additional-hooks.html