import './class-component-hooks';
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Outside from '@/views/Outside.vue'
import Dojo from '@/views/Dojo.vue'
import Battle from '@/views/Battle.vue'
import BattleRoom from '@/views/BattleRoom.vue'
import Deck from '@/views/Deck.vue'
import Adventure1Tour from '@/views/Adventure1Tour.vue'
import DeckTour from '@/views/DeckTour.vue'
import Invaders from '@/views/Invaders.vue'
import Runner from '@/views/Runner.vue'
import Bubble from '@/views/Bubble.vue'
import Octo from '@/views/Octo.vue'
import Quack from '@/views/Quack.vue'
import Tower from '@/views/Tower.vue'
import Platformer from '@/views/Platformer.vue'
import Dragon from '@/views/Dragon.vue'
import Arcade from '@/views/Arcade.vue'
import Corridor from '@/views/Corridor.vue'
import Daily from '@/views/Daily.vue'
import Adventuring from '@/views/Adventure.vue'
import Museum from '@/views/Museum.vue'
import Academy from '@/views/Academy.vue'
import Hub from '@/views/Hub.vue'
import Ep1 from '@/views/Ep1.vue'
import Ep2 from '@/views/Ep2.vue'
import Ep3 from '@/views/Ep3.vue'
import Ep4 from '@/views/Ep4.vue'
import Ep5 from '@/views/Ep5.vue'
import Ep6 from '@/views/Ep6.vue'
import Chimpvolution from '@/views/Chimpvolution.vue'
import AdventureTour1 from '@/views/AdventureTour1.vue';
import AdventureTour2 from '@/views/AdventureTour2.vue';
import AdventureTour3 from '@/views/AdventureTour3.vue';
import AdventureTour4 from '@/views/AdventureTour4.vue';
import AdventureTour5 from '@/views/AdventureTour5.vue';
import AdventureTour6 from '@/views/AdventureTour6.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/outside',
    name: 'outside',
    component: Outside,
    meta: {
      pageTitle: 'Dojo Courtyard'
    }
  },
  {
    path: '/dojo',
    name: 'dojo',
    component: Dojo,
    meta: {
      pageTitle: 'Dojo Hall'
    }
  },
  {
    path: '/battle',
    name: 'battle',
    component: BattleRoom,
    meta: {
      pageTitle: 'Battle Room'
    }
  },
  {
    path: '/deck',
    name: 'deck',
    component: DeckTour,
    meta: {
      pageTitle: 'Adventure Deck'
    }
  },
  {
    path: '/adventure-tour-1',
    name: 'adventure-tour-1',
    component: AdventureTour1,
    meta: {
      pageTitle: 'Adventure #1 Tour'
    }
  },
  {
    path: '/adventure-tour-2',
    name: 'adventure-tour-2',
    component: AdventureTour2,
    meta: {
      pageTitle: 'Adventure #2 Tour'
    }
  },
  {
    path: '/adventure-tour-3',
    name: 'adventure-tour-3',
    component: AdventureTour3,
    meta: {
      pageTitle: 'Adventure #3 Tour'
    }
  },
  {
    path: '/adventure-tour-4',
    name: 'adventure-tour-4',
    component: AdventureTour4,
    meta: {
      pageTitle: 'Adventure #4 Tour'
    }
  },
  {
    path: '/adventure-tour-5',
    name: 'adventure-tour-5',
    component: AdventureTour5,
    meta: {
      pageTitle: 'Adventure #5 Tour'
    }
  },
  {
    path: '/adventure-tour-6',
    name: 'adventure-tour-6',
    component: AdventureTour6,
    meta: {
      pageTitle: 'Adventure #6 Tour'
    }
  },
  {
    path: '/arcade',
    name: 'arcade',
    component: Arcade,
    meta: {
      pageTitle: 'Chimpers Arcade'
    }
  },
  {
    path: '/invaders',
    name: 'invaders',
    component: Invaders,
    meta: {
      pageTitle: 'Rise of the Undead'
    }
  },
  {
    path: '/runner',
    name: 'runner',
    component: Runner,
    meta: {
      pageTitle: 'Chimp Run'
    }
  },
  {
    path: '/bubble',
    name: 'bubble',
    component: Bubble,
    meta: {
      pageTitle: 'Chimp Tag'
    }
  },
  {
    path: '/octo',
    name: 'octo',
    component: Octo,
    meta: {
      pageTitle: 'Ocean Quest'
    }
  },
  {
    path: '/quack',
    name: 'quack',
    component: Quack,
    meta: {
      pageTitle: 'Quack Quest'
    }
  },
  {
    path: '/tower',
    name: 'tower',
    component: Tower,
    meta: {
      pageTitle: 'Chimp Drop'
    }
  },
  {
    path: '/platformer',
    name: 'platformer',
    component: Platformer,
    meta: {
      pageTitle: 'Inferno Escape'
    }
  },
  {
    path: '/dragon',
    name: 'dragon',
    component: Dragon,
    meta: {
      pageTitle: 'Dragon Dash'
    }
  },
  {
    path: '/corridor',
    name: 'corridor',
    component: Corridor,
    meta: {
      pageTitle: 'Battle Corridor'
    }
  },
  {
    path: '/daily/:id',
    name: 'daily',
    component: Daily
  },
  {
    path: '/adventure',
    name: 'adventure',
    component: Adventuring,
    meta: {
      pageTitle: 'Adventure Map'
    }
  },
  {
    path: '/museum',
    name: 'museum',
    component: Museum,
    meta: {
      pageTitle: 'Adventure Museum'
    }
  },
  {
    path: '/academy',
    name: 'academy',
    component: Academy,
    meta: {
      pageTitle: 'Academy Room'
    }
  },
  {
    path: '/hub',
    name: 'hub',
    component: Hub,
    meta: {
      pageTitle: 'Learning Hub'
    }
  },
  {
    path: '/ep1',
    name: 'ep1',
    component: Ep1,
    meta: {
      pageTitle: 'Episode 1'
    }
  },
  {
    path: '/ep2',
    name: 'ep2',
    component: Ep2,
    meta: {
      pageTitle: 'Episode 2'
    }
  },
  {
    path: '/ep3',
    name: 'ep3',
    component: Ep3,
    meta: {
      pageTitle: 'Episode 3'
    }
  },
  {
    path: '/ep4',
    name: 'ep4',
    component: Ep4,
    meta: {
      pageTitle: 'Episode 4'
    }
  },
  {
    path: '/ep5',
    name: 'ep5',
    component: Ep5,
    meta: {
      pageTitle: 'Episode 5'
    }
  },
  {
    path: '/ep6',
    name: 'ep6',
    component: Ep6,
    meta: {
      pageTitle: 'Episode 6'
    }
  },
  {
    path: '/chimpvolution',
    name: 'chimpvolution',
    component: Chimpvolution,
    meta: {
      pageTitle: 'Chimpvolution'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 })
})

export default router
