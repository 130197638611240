import { render, staticRenderFns } from "./LeaderboardChimpSlot.vue?vue&type=template&id=3ad2dc96&scoped=true&"
import script from "./LeaderboardChimpSlot.vue?vue&type=script&lang=ts&"
export * from "./LeaderboardChimpSlot.vue?vue&type=script&lang=ts&"
import style0 from "./LeaderboardChimpSlot.vue?vue&type=style&index=0&id=3ad2dc96&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad2dc96",
  null
  
)

export default component.exports