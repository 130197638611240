import { ISpritesheetData } from "pixi.js";

/**
 * Exclamation mark spritesheet atlas
 */
export const exclamAtlas: ISpritesheetData = {
  frames: {
    "0": {
      "frame": { "x": 0, "y": 0, "w": 58, "h": 48 },
      "sourceSize": { "w": 58, "h": 48 },
    },
    "1": {
      "frame": { "x": 58, "y": 0, "w": 58, "h": 48 },
      "sourceSize": { "w": 58, "h": 48 },
    }
  },
  meta: { "format": "RGBA8888", "size": { "w": 116, "h": 48 }, "scale": "1.5" },
  animations: { exclam: ["0", "1"] }
}


/**
 * Chimp idle spritesheet atlas
 */
export const chimpIdleAtlas: ISpritesheetData = {
  frames: {
    "0": {
      "frame": { "x": 0, "y": 0, "w": 96, "h": 96 },
      "sourceSize": { "w": 96, "h": 96 }
    },
    "1": {
      "frame": { "x": 96, "y": 0, "w": 96, "h": 96 },
      "sourceSize": { "w": 96, "h": 96 }
    },
    "2": {
      "frame": { "x": 192, "y": 0, "w": 96, "h": 96 },
      "sourceSize": { "w": 96, "h": 96 }
    },
    "3": {
      "frame": { "x": 288, "y": 0, "w": 96, "h": 96 },
      "sourceSize": { "w": 96, "h": 96 }
    },
    "4": {
      "frame": { "x": 384, "y": 0, "w": 96, "h": 96 },
      "sourceSize": { "w": 96, "h": 96 }
    },
    "5": {
      "frame": { "x": 480, "y": 0, "w": 96, "h": 96 },
      "sourceSize": { "w": 96, "h": 96 }
    },
    "6": {
      "frame": { "x": 576, "y": 0, "w": 96, "h": 96 },
      "sourceSize": { "w": 96, "h": 96 }
    },
    "7": {
      "frame": { "x": 672, "y": 0, "w": 96, "h": 96 },
      "sourceSize": { "w": 96, "h": 96 }
    }
  },
  meta: {
    app: "guasam",
    image: "https://ipfs.chimpers.xyz/ipfs/QmdK2s5nYiAyDMTgTs7Bg9quCNwgReAxs4dCvxSPYiCfAh/11474.png",
    format: "RGBA8888",
    size: { w: 768, h: 96 },
    scale: 1,
  },
  animations: {
    chimp: ["0", "1", "2", "3", "4", "5"]
  }
};