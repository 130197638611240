import { render, staticRenderFns } from "./ChimpvolutionItem.vue?vue&type=template&id=26ee8440&scoped=true&"
import script from "./ChimpvolutionItem.vue?vue&type=script&lang=ts&"
export * from "./ChimpvolutionItem.vue?vue&type=script&lang=ts&"
import style0 from "./ChimpvolutionItem.vue?vue&type=style&index=0&id=26ee8440&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26ee8440",
  null
  
)

export default component.exports