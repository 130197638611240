import { Viewport } from "pixi-viewport";
import { AnimatedSprite, Application, Graphics, IApplicationOptions, IPointData, Rectangle, Sprite, Spritesheet, Texture, utils } from "pixi.js";
import Vue from "vue";
import { chimpIdleAtlas, exclamAtlas } from "./mapHelpers";
import { AdventureTourInfo, adventuresTourInfo } from "@/helpers/adventures";

export type AreaPoint = {
  x: number;
  y: number;
  width: number;
  height: number;
  text?: string;
}

export abstract class PixiAdventureMap extends Vue {
  app: Application;
  appOptions: Partial<IApplicationOptions> = { backgroundColor: '#1a1a1a', antialias: false }
  viewport: Viewport;
  worldWidth = 2047;
  worldHeight = 1369;
  maxZoomIn = 5;
  wheelSmooth = 30;
  adventureNumber = 0;
  inEditor = false;
  viewportDragKey = 'Space';
  isViewportDragKeyHeld = false;
  debugAreaPoints = false;
  areaSoundTimeout: number;
  killChimposSpeech = false;
  showLeaderboard = false;

  /**
   * Is map currently non interactive?
   */
  get mapNotInteractive() {
    return false;
  }

  /**
   * Initialize pixijs application.
   */
  initialize() {
    // Check that the user can access this view, or redirect to home
    if (!this.$globals.canAccessView) {
      window.location.href = "/";
      return;
    }

    // Create new pixijs app
    this.app = new Application(this.appOptions);
    // Append pixi app canvas into container element
    (this.$refs.pixiContainer as HTMLElement).appendChild(this.app.view as HTMLCanvasElement);
    // Create canvas viewport
    this.createViewport();
    // Listen for the window resize event
    window.addEventListener('resize', this.handleResize.bind(this));
    // Resize app renderer based on browser window size
    this.handleResize();

    // Listen for the window keyboard events
    window.addEventListener('keypress', this.handleKeyPress.bind(this));
    window.addEventListener('keyup', this.handleKeyUp.bind(this));
    // Switch app stage's eventmode based on "mapNotInteractive" state.
    this.$watch('mapNotInteractive', (newValue) => {
      this.app.stage.eventMode = newValue ? 'none' : 'auto';
    });
  }

  /**
   * Destroys stuff related to pixijs application.
   */
  terminate() {
    // Mark chimpos speech as killed
    this.killChimposSpeech = true;
    // Remove windowevent listners
    window.removeEventListener('resize', this.handleResize.bind(this));
    window.removeEventListener('keypress', this.handleKeyPress.bind(this));
    window.removeEventListener('keyup', this.handleKeyUp.bind(this));

    // Clear pixijs related stuff with slight delay to prevent (X) icon displayed in corner.
    const timeoutId = setTimeout(() => {
      utils.clearTextureCache();
      this.viewport.destroy();
      this.app.destroy();
      clearTimeout(timeoutId);
    }, 500);
  }

  /**
   * Handle resizing of application based on window size.
   */
  protected handleResize() {
    this.app.renderer.resize(window.innerWidth, window.innerHeight);
    this.viewport.resize(window.innerWidth, window.innerHeight);
  }

  /**
   * Handle keypress event
   * @param event Keyboard Event
   */
  protected handleKeyPress(event: KeyboardEvent) {
    if (event.code === this.viewportDragKey && !this.isViewportDragKeyHeld) {
      this.isViewportDragKeyHeld = true;
    }
  }

  /**
   * Handle keyup event
   * @param event Keyboard Event
   */
  protected handleKeyUp(event: KeyboardEvent) {
    if (event.code === this.viewportDragKey && this.isViewportDragKeyHeld) {
      this.isViewportDragKeyHeld = false;
    }
  }

  /**
   * Create pixijs viewport with pan + zoom.
   */
  protected async createViewport() {
    this.viewport = new Viewport({
      worldWidth: this.worldWidth,
      worldHeight: this.worldHeight,
      events: this.app.renderer.events // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
    });

    // Configure viewport with zoom and pan effects
    this.viewport
      .wheel({ smooth: this.wheelSmooth })
      .drag(this.inEditor ? { keyToPress: [this.viewportDragKey] } : {})
      .decelerate()
      .fitWorld()
      .clamp({ direction: 'all' })
      .clampZoom({
        minWidth: this.viewport.worldWidth / this.maxZoomIn,
        minHeight: this.viewport.worldHeight / this.maxZoomIn,
        maxWidth: this.viewport.worldWidth,
        maxHeight: this.viewport.worldHeight,
      });

    // Draw viewport on app canvas
    this.app.stage.addChild(this.viewport);
    // Move viewport to center of app
    this.viewport.moveCenter(this.app.view.width / 2, 0);
  }

  /**
   * Applies scale for map sprite based on world aspect ratio.
   * @param sprite Map sprite
   */
  protected scaleWorldMapSprite(sprite: Sprite) {
    const scaleX = this.worldWidth / sprite.width;
    const scaleY = this.worldHeight / sprite.height;
    const scale = Math.min(scaleX, scaleY);
    sprite.scale.set(scale);
  }

  /**
   * Renders map area points, exclamation sprites, text calculated position at given area points.
   * @param areaPoints
   */
  protected async renderAreaPoints(areaPoints: AreaPoint[]) {
    const textBox = this.$refs['textbox'] as HTMLDivElement;

    // Create exclamation sprite
    const exclamSheet = new Spritesheet({
      data: exclamAtlas,
      cachePrefix: 'exclam_',
      texture: Texture.from(require('../assets/deck-tour/spritesheets/exclam.png'))
    });

    // Parse exclamsheet
    await exclamSheet.parse();

    // Loop through all of them
    areaPoints.forEach((a, i) => {

      const area = new Graphics();
      if (this.debugAreaPoints) {
        area.beginFill('cyan', 0.5);
        area.drawRect(a.x, a.y, a.width, a.height);
        area.endFill();
      }

      // Adjust hit area for interactivity and render!
      area.hitArea = new Rectangle(a.x, a.y, a.width, a.height);
      area.eventMode = 'static';
      this.viewport.addChild(area);

      area.on('mouseover', () => {
        const pos = this.viewport.toScreen(a.x, a.y);
        const width = 480;
        pos.x += 65; // offset
        textBox.style.display = 'block';
        textBox.style.left = `${pos.x}px`;
        textBox.style.top = `${pos.y}px`;
        textBox.innerText = a.text as string;
        textBox.style.pointerEvents = 'none';
        textBox.style.width = `${width}px`;

        // Fix: Screen Overlapping textbox position
        const overlappedRight = (pos.x + width) > this.app.view.width;
        if (overlappedRight) {
          textBox.style.left = `calc(${textBox.style.left} - ${textBox.style.width})`;
        }

        // Play area sound effect
        clearTimeout(this.areaSoundTimeout);
        this.areaSoundTimeout = setTimeout(() => {
          this.$globals.audio(require("../assets/select.mp3"));
        }, 20) as unknown as number;
      });

      area.on('mouseout', () => {
        // Clear textbox props
        textBox.innerText = '';
        textBox.style.display = 'none';
        textBox.style.left = `0px`;
        textBox.style.top = `0px`;
        textBox.innerText = '';
        textBox.style.pointerEvents = 'auto';

        // Clear timeout
        clearTimeout(this.areaSoundTimeout);
      });

      // Exclam sprite
      const icon = new AnimatedSprite(exclamSheet.animations.exclam);
      icon.animationSpeed = 0.0666;
      icon.alpha = 0.85;
      icon.position.set(a.x + a.width / 2, a.y - icon.height / 2);
      icon.eventMode = 'none';
      const timeoutId = setTimeout(() => { icon.play(), clearTimeout(timeoutId) }, 150 * i);
      this.viewport.addChild(icon);
    });
  }

  /**
   * Renders winners animated spritesheet at given area points.
   * @param areaPoints
   */
  protected async renderWinnersAreaPoints(winnerAreaPoints: AreaPoint[], scale = 1.5) {
    // Use adventure info
    const adventure = adventuresTourInfo.find(a => a.id == this.adventureNumber) as AdventureTourInfo;

    // Loop through all winner tokens to render their spritesheets!
    adventure.winnersTokens.forEach(async (v, i) => {
      const chimpId = v < 101 ? v : v + 10000;

      // Override chimp atlas
      chimpIdleAtlas.meta.scale = scale;
      chimpIdleAtlas.meta.image = `https://ipfs.chimpers.xyz/ipfs/QmdK2s5nYiAyDMTgTs7Bg9quCNwgReAxs4dCvxSPYiCfAh/${chimpId}.png`;

      // Create spritesheet from image
      const spritesheet = new Spritesheet({
        cachePrefix: `chimp_${chimpId}_idle_`,
        texture: Texture.from(chimpIdleAtlas.meta.image),
        data: chimpIdleAtlas
      });

      // Parse spritesheet
      await spritesheet.parse();

      // Create aniamted spritesheet
      const anim = new AnimatedSprite(spritesheet.animations.chimp);
      anim.animationSpeed = 0.1666;
      anim.position.set(winnerAreaPoints[i].x, winnerAreaPoints[i].y);
      anim.pivot.set(anim.width / 4, anim.height / 1.5);
      anim.eventMode = 'none';
      const timeoutId = setTimeout(() => { anim.play(); clearTimeout(timeoutId) }, 150 * i);
      this.viewport.addChild(anim);
    });
  }

  /**
   * Zooms viewport at given position point.
   * @param position
   */
  protected zoomViewportAt(position: IPointData, time = 500) {
    this.viewport.animate({
      scale: this.viewport.scale.x * 2,
      position,
      time,
      ease: "easeInOutSine",
    });
  }

  /**
   * Show leaderboard MC speech
   * @returns
   */
  protected async chimpoLeaderboardSpeech() {
    await this.$globals.chimpo("To see how all the other chimps on this adventure did, check out the leaderboard!");
    const mcElem = document.querySelector('.chimpo');
    const btnElem = document.createElement('button');
    btnElem.className = 'speech-button';
    btnElem.textContent = 'Show Leaderboard';
    btnElem.onclick = () => { this.showLeaderboard = true };
    mcElem?.appendChild(btnElem);
  }
}