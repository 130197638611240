/**
 * Shorten wallet address, example: (0xabcd...efgh)
 * @param address Wallet address
 * @returns
 */
export function shortenAddress(address?: string) {
    return address && `${address.substring(0, 4)}...${address.substring(address.length - 4)}`
}

/**
 * Get ENS name from wallet address
 * @param address Wallet address
 * @returns
 */
export async function getEns(address: string) {
    const ens = await window.ManifoldEthereumProvider.provider().lookupAddress(address);
    return ens ? ens : shortenAddress(address);
}