export type AdventureTourInfo = {
  id: number;
  winnersTokens: number[];
  startDate: string;
  endDate: string;
}

export const adventuresTourInfo: AdventureTourInfo[] = [
  {
    id: 1,
    winnersTokens: [4766],
    startDate: '2023-02-02',
    endDate: '2023-02-20'
  },
  {
    id: 2,
    winnersTokens: [23, 2492, 4635, 1210],
    startDate: '2023-03-16',
    endDate: '2023-04-04'
  },
  {
    id: 3,
    winnersTokens: [915, 3656, 5346],
    startDate: '2023-04-29',
    endDate: '2023-05-17'
  },
  {
    id: 4,
    winnersTokens: [4116, 5337, 4667, 2493],
    startDate: '2023-06-12',
    endDate: '2023-07-02'
  },
  {
    id: 5,
    winnersTokens: [3231],
    startDate: '2023-07-25',
    endDate: '2023-08-14'
  },
  {
    id: 6,
    winnersTokens: [92],
    startDate: '2023-09-19',
    endDate: '2023-10-09'
  }
];