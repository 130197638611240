import { render, staticRenderFns } from "./ChatBox.vue?vue&type=template&id=ad20e6de&scoped=true&"
import script from "./ChatBox.vue?vue&type=script&lang=ts&"
export * from "./ChatBox.vue?vue&type=script&lang=ts&"
import style0 from "./ChatBox.vue?vue&type=style&index=0&id=ad20e6de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad20e6de",
  null
  
)

export default component.exports